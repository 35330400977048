import RefreshIcon from 'assets/svg/refresh.svg'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import { Size } from 'types/size'

export interface SpinnerProps {
  size?: Size
  className?: string
}
const Spinner: React.FC<SpinnerProps> = ({ size, className }) => {
  return (
    <RefreshIcon
      className={classNameBuilder(
        'animate-spin',
        sizeClassMap[size],
        !size && !className && sizeClassMap[size],
        className
      )}
    />
  )
}

export default Spinner

const sizeClassMap: Record<Size, string> = {
  xs: 'w-2 h-2',
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
  lg: 'w-8 h-8',
  xl: 'w-10 h-10',
}
