import Column from 'components/atoms/column'
import Spinner from 'components/atoms/spinner'
import { useRouter } from 'next/dist/client/router'
import React from 'react'

export const HomeRoute: React.FC = () => {
  const { push } = useRouter()

  React.useEffect(() => {
    push('/meus-cursos')
  }, [])
  return (
    <Column className="h-screen flex-1 items-center justify-center">
      <Spinner size="md" className="text-indigo-400" />
    </Column>
  )
}

export default HomeRoute
